// import { GetStaticProps } from 'next'
import Layout from '../components/common/Layout'
import { ContentfulGlobalSettings, ContentfulGraphAsset, ContentfulNotFoundPageGraph } from '../contentful/types'
import { GetStaticProps } from 'next'
import { getNotFoundProps } from '../contentful/api'
import { Box } from '@chakra-ui/react'
import HeroNotFound from '../components/heroes/HeroNotFound'

function NotFound({
  settings,
  pageData,
}: {
  settings: ContentfulGlobalSettings
  pageData: ContentfulNotFoundPageGraph
}): JSX.Element {
  return (
    <Layout
      metadata={{
        description: 'This Page Cannot Be Found',
        title: 'Not Found',
        metaShareImage: {
          url: '',
        } as ContentfulGraphAsset,
        twitterShareImage: {
          url: '',
        } as ContentfulGraphAsset,
      }}
      settings={settings}
    >
      <Box as="article" w="100%">
        <HeroNotFound zIndex="heroHome" heading={pageData.pageTitle.json} subheading={pageData.pageSubTitle} href="/" />
      </Box>
    </Layout>
  )
}

export default NotFound

export const getStaticProps: GetStaticProps = getNotFoundProps()
